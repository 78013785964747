import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/hero"
import FullCTA from "../components/fullCTA"
import { BodyText } from "../components/bodyText"
import OverlayText from "../components/overlayText"
import CTA from "../components/cta"

const Interior = ({ data }) => {
  const article = data.tst.Page || {}
  if (!article.pageMetaData) {
    article.pageMetaData = {}
  }
  article.pageMetaData.url = article.url

  return (
    <Layout meta={article.pageMetaData || { title: article.title }}>
      {article.hero && <Hero data={article.hero} className="fixed" />}
      <OverlayText className={!article.hero ? "no-hero" : ""}>
        <BodyText article={article} align="left" />
        {article.ctas &&
          article.ctas.map(cta => {
            return cta.full ? (
              <FullCTA data={cta} />
            ) : (
              <div className="container pb-xl">
                <div className="inner">
                  <CTA data={cta} color="light" />
                </div>
              </div>
            )
          })}
      </OverlayText>
    </Layout>
  )
}

export default Interior

export const query = graphql`
  query InteriorQuery($id: ID!) {
    tst {
      Page(where: { id: $id }) {
        name
        pageTitle
        text
        url
        ctas {
          ...CTA
        }
        ctasSort
        features {
          ...Feature
        }
        hero {
          ...Hero
        }
        pageMetaData {
          ...Meta
        }
      }
    }
  }
`
